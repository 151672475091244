async function checkAccount() {
	if (typeof web3 !== 'undefined') {
	  const accountss = await ethereum.request({ method: 'eth_accounts' })
	    if(accountss.length == 0)  {
	      var addybtn = document.getElementById("addybtn").style.display = "none"
	      var loading = document.getElementById("loading").style.display = "none"
	      var notconnect = document.getElementById("notconnect").style.display = "inline-block"
	    } else {
        	const account = accountss[0];
        	if(window.ethereum.networkVersion != 1 && window.location.href.toString().split(window.location.host)[1].split('/')[1] !== "getonmainnet") {
        		document.getElementById("loader").style.display = "block"
        		location.href = "/getonmainnet"
        	}
        	if(window.ethereum.networkVersion == 1 && window.location.href.toString().split(window.location.host)[1].split('/')[1] == "waitlists") {
				const formInputEthAddresser = document.querySelector('input.wallet_address');
				formInputEthAddresser.value = accountss[0];
			
        	}
	      	var loading = document.getElementById("loading").style.display = "none"
		    document.getElementById("addybtn").style.display = "inline-block"
		    var addybtn = document.getElementById("addybtn").innerText = "Connected " + account.slice(0,3) + "..." + account.slice(account.length - 4,account.length);
		    // var addybtn = document.getElementById("addybtn").innerText = "Connected " + account.slice(0,4) + "..." + account.slice(account.length - 4,account.length);
		    // listening for changes.
		    ethereum.on('chainChanged', (_chainId) => {
		    	document.getElementById("loader").style.display = "block"
		    	console.log('chainchanged')
		    	if(_chainId != 1 && window.location.href.toString().split(window.location.host)[1].split('/')[1] !== "getonmainnet") {
        			location.href = "/getonmainnet"
        		} else if (_chainId == 1) {
        			location.href = "/"
        		}
		    });
			ethereum.on('accountsChanged', (accounts) => {
				document.getElementById("loader").style.display = "block"
			  if(accounts.length >= 1) {
			      const account = accounts[0];
			      location.href = window.location.href
			  } else {
			  	console.log('no account')
			    location.href = window.location.href
			  }
			})

	    }
	}
}
window.onload = function checker() {
    checkAccount()
}